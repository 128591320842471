<template>
	<div id="app" :class="classes">
		<keep-alive :exclude="['OrderEdit']">
			<transition
				name="fade"
				mode="out-in"
			>
				<router-view/>
			</transition>
		</keep-alive>
	</div>
</template>

<script>
	const _ = require('lodash');

	export default {
		name: "App",

		computed: {
			classes() {
				let classes = {};
				const layout =_.get(this.$route.meta, 'layout', 'default');
				classes[layout] = true;
				return classes;
			}
		}
	}
</script>

<style scoped>
	@import './assets/css/museo-sans-rounded.css';
	@import './assets/css/loading-spinner.css';
	@import 'assets/css/tooltip.css';
	@import 'assets/css/xs.css';
	@import './assets/css/responsive.css';
	@import '~vue-multiselect/dist/vue-multiselect.min.css';

	#app.center,
	#app.login {
		height: 100%;
	}

	#app.login,
	#app.login /deep/ footer {
		background-color: #eaeaea;
	}

	#app.default {
		background-color: white;
		min-height: 100%;
	}

	/deep/ .ej-blank-page {
		/*padding: 0 5px;*/
		height: 100%;
	}

	#app.default /deep/ .ej-content .ej-breadcrumb {
		display: none;
	}

	#app.default /deep/ .ej-content .header {
		padding: 5px;
	}

	#app.default /deep/ .ej-content .content {
		padding: 0 5px;
		margin-bottom: 20px;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		#app.default /deep/ .ej-content .ej-breadcrumb {
			display: block;
			padding: 30px 20px 0 20px;
		}

		#app.default /deep/ .ej-content .header {
			padding: 10px 20px 20px 20px;
		}

		#app.default /deep/ .ej-content .content {
			padding: 0 20px 20px 20px;
		}
	}
</style>
