<template>
	<multiselect
		:searchable="true"
		:close-on-select="true"
		:preserve-search="true"
		:clear-on-select="false"
		:internal-search="true"
		select-label="Selecionar"
		deselect-label="Deselecionar"
		v-bind="$attrs"
		v-on="$listeners"
		:placeholder="placeholder"
	>
		<slot>
			<span slot="noOptions">{{ noOptions }}</span>
			<span slot="noResult">{{ noResult }}</span>
		</slot>
	</multiselect>
</template>

<script>
	import Multiselect from "vue-multiselect";

	export default {
		name: "EjSelect",

		props: {
			placeholder: {
				type: String,
				default: () => 'Buscar'
			}
		},

		data() {
			return {
				noResult: 'Nenhum resultado encontrado.',
				noOptions: 'Lista vazia, digite algo.',
			}
		},

		components: {
			Multiselect
		}
	}
</script>

<style scoped>

</style>
