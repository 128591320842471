import Swal from "sweetalert2";
const _ = require('lodash');
const sha1 = require('js-sha1');

const break_line_to_br = function(str) {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

const exibeErro = async function(error) {
	console.error(error);

	if (Object.prototype.hasOwnProperty.call(error, 'response')) {
	// if (_.get(error, 'constructor.name') === "Error") {
		const message = _.get(error.response.data, 'message');
		const errors = _.get(error.response.data, 'errors', []);

		//Para quando retorna 403 sem uma mensagem
		if (error.response.status === 403/* && (message.trim() === "" || !message)*/) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: "Proibido!"
			});

		//Para quando retorna 401 sem uma mensagem
		} else if (error.response.status === 401/* && (message.trim() === "" || !message)*/) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Não autenticado! Você será redirecionado para o login."
			});
			window.location = '/login';
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				html: Object.entries(errors).reduce(
					function(ac, error) {
						return ac += `<span style="text-decoration: underline">${error[0]}</span>:<br> ${error[1].reduce(
							(ac2,item) => ac2 += `* ${item}<br>`,
							""
						)}`;
					},
					`<strong>${break_line_to_br(message)}</strong><br>`
				)
			});
		}

	} else {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: error
		});
	}
};

const customWatch = {
	handler() {
		this.requestData();
	},
	deep: true
};

const whatsappOrderShareLink = (order_id) => {
	const token = sha1(`${order_id}|pedidos`);
	return whatsAppLink(`${process.env.VUE_APP_API_URL}/orders/${order_id}/share/pdf?token=${token}`);
}

const emailOrderShareLink = (order_id) => {
	const token = sha1(`${order_id}|pedidos`);
	return `mailto:?subject=Pedido ${order_id}&body=${process.env.VUE_APP_API_URL}/orders/${order_id}/share/pdf?token=${token}`;
}

const whatsAppLink = (text, phone = '') => {
	return `https://wa.me/${phone}?text=${encodeURI(text)}`;
}

const sanitize = function (obj) {
	return _.mapValues(obj, function(v) {
		if (!v) return v;

		const type = typeof v;
		switch(type) {
			case 'string':
				// eslint-disable-next-line no-case-declarations
				const str = v.trim();
				return str === '' ? null : str;

			case 'undefined':
				//
				break;

			case 'object':
				return sanitize(v);

			default:
				return v;
		}
	});
};

export {
	customWatch,
	whatsAppLink,
	whatsappOrderShareLink,
	emailOrderShareLink,
	exibeErro,
	break_line_to_br,
	sanitize
};
