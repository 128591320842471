import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker'

//Routing
import router from './router';

//Vuex
import store from './store';

//Page Title
import VuePageTitle from 'vue-page-title';
Vue.use(VuePageTitle, { router });

//Icons
import FontAwesomeIcon from './icons';
Vue.component('font-awesome-icon', FontAwesomeIcon);

//Lodash
import _ from 'lodash';
Vue.prototype._ = _;

//Componentes globais
import EjPage from "@/components/EjPage";
import EjBackButton from "@/components/EjBackButton";
import EjBlankPage from "@/components/EjBlankPage";
import EjBreadcrumb from "@/components/EjBreadcrumb";
import EjButton from "@/components/EjButton";
import EjButtons from "@/components/EjButtons";
import EjContent from "@/components/EjContent";
import EjDatePicker from "@/components/EjDatePicker";
import EjDesktopHeader from "@/components/EjDesktopHeader";
import EjFloatingButton from "@/components/EjFloatingButton";
import EjFooter from "@/components/EjFooter";
import EjMenuButton from "@/components/EjMenuButton";
import EjMobileHeader from "@/components/EjMobileHeader";
import EjMobileMenu from "@/components/EjMobileMenu";
import EjModal from "@/components/EjModal";
import EjOrderIcon from "@/components/EjOrderIcon";
import EjSelect from "@/components/EjSelect";
import EjTitle from "@/components/EjTitle";
import EjToggleButton from "@/components/EjToggleButton";
import EjToolbar from "@/components/EjToolbar";

//Carrega componentes globais
[
	EjAsterisk,
	EjBackButton,
	EjBlankPage,
	EjBreadcrumb,
	EjButton,
	EjButtons,
	EjContent,
	EjDatePicker,
	EjDesktopHeader,
	EjFloatingButton,
	EjFooter,
	EjMenuButton,
	EjMobileHeader,
	EjMobileMenu,
	EjModal,
	EjOrderIcon,
	EjPage,
	EjSelect,
	EjTitle,
	EjToggleButton,
	EjToolbar
].forEach(component => {
	Vue.component(component.name, component);
});

window.axios = require('axios');
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

//i18n
Vue.prototype.i18n = require('@/i18n');

//moment
import moment from 'moment';
Vue.prototype.moment = moment;

//Currency Input
import VueCurrencyInput from 'vue-currency-input';
Vue.use(VueCurrencyInput, {
	globalOptions: {
		autoDecimalMode: true,
		precision: 2,
		currency: 'BRL',
		distractionFree: false
	}
});

//Toggle Button
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton)

//Mask
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

//Diretivas personalizadas
import lowercase from "@/diretives/lowercase";
import uppercase from "@/diretives/uppercase";
Vue.directive('lowercase', lowercase);
Vue.directive('uppercase', uppercase);

import * as VueDeepSet from 'vue-deepset';
Vue.use(VueDeepSet);

//v-tooltip
import VTooltip from 'v-tooltip';
import EjAsterisk from "@/components/EjAsterisk";
Vue.use(VTooltip);

String.prototype.ucwords = function(str) {
	str = this.toLowerCase();
	return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
		function(s){
			return s.toUpperCase();
		});
};

if (process.env.NODE_ENV === 'development')
	window.defaultQueryParams = {XDEBUG_SESSION_START: ""};
else
	window.defaultQueryParams = {};

Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
