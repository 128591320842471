import store from "@/store";

const routes = [
	{
		path: '/people',
		name: 'people',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "people" */ '../views/people/index'),
		meta: {
			title: 'Pessoas'
		}
	},
	{
		path: '/people/create',
		name: 'people.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "people.edit" */ '../views/people/edit'),
		meta: {
			title: 'Criar Pessoa'
		}
	},
	{
		path: '/people/:id',
		name: 'people.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "people.edit" */ '../views/people/edit'),
		meta: {
			title: 'Editar Pessoa'
		}
	}
];

export default routes;
