<template>
	<toggle-button
		:font-size="14"
		:height="30"
		:width="width"
		v-bind="$attrs"
		v-on="$listeners"
	/>
</template>

<script>
	export default {
		name: "EjToggleButton",

		props: {
			width: {
				type: Number,
				default: 70
			}
		}
	}
</script>

<style scoped>

</style>
