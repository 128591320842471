export default {
	namespaced: true,

	actions: {
		//REST Endpoints
		index(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/cities?${searchParams.toString()}`);
		}
	}
}
