import { library } from '@fortawesome/fontawesome-svg-core';

//Importação dos ícones sólidos
import {
	faHome,
	faShoppingCart,
	faSort,
	faTachometerAlt,
	faUsers,
	faPlus,
	faBars,
	faFilter,
	faCaretDown,
	faFileAlt as fasFileAlt,
	faDownload,
	faEllipsisH,
	faCog,
	faSignOutAlt,
	faBoxes,
	faSortUp,
	faSortAmountUp,
	faSortDown,
	faSortAmountDown,
	faArrowLeft,
	faUserLock,
	faWindowClose,
	faRocket,
	faSyncAlt,
	faPuzzlePiece,
	faTruckLoading,
	faClipboardCheck,
	faSpinner,
	faCheck,
	faCircle,
	faUserClock,
	faBusinessTime,
	faShippingFast
} from '@fortawesome/free-solid-svg-icons';

//Importação dos ícones regulares
import {
	faEdit,
	faFileAlt,
	faEnvelope,
	faArrowAltCircleDown,
	faTrashAlt,
	faEye,
	faThumbsDown,
	faThumbsUp,
	faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';

//Importação dos ícones de marca
import {
	faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

library.add(
	faHome,
	faShoppingCart,
	faEdit,
	faFileAlt,
	faEnvelope,
	faArrowAltCircleDown,
	faTrashAlt,
	faSort,
	faTachometerAlt,
	faUsers,
	faPlus,
	faBars,
	faFilter,
	faCaretDown,
	fasFileAlt,
	faDownload,
	faEllipsisH,
	faEye,
	faSpinner,
	faCog,
	faSignOutAlt,
	faBoxes,
	faSortUp,
	faSortAmountUp,
	faSortDown,
	faSortAmountDown,
	faArrowLeft,
	faUserLock,
	faWindowClose,
	faThumbsDown,
	faThumbsUp,
	faRocket,
	faSyncAlt,
	faQuestionCircle,
	faPuzzlePiece,
	faTruckLoading,
	faClipboardCheck,
	faWhatsapp,
	faCheck,
	faCircle,
	faUserClock,
	faBusinessTime,
	faShippingFast
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default FontAwesomeIcon;
