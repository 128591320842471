import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Categories from './categories';
import Orders from './orders';
import Passwords from './passwords';
import People from './people';
import Products from './products';
import Roles from './roles';
import Users from './users';

import store from "@/store";

const routes = [
	{
		path: '/',
		name: 'home',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "home" */ '../views/home/index'),
		meta: {
			title: 'Home',
			layout: 'default'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/index'),
		meta: {
			title: 'Login',
			layout: 'login'
		}
	},
	...Categories,
	...Orders,
	...Passwords,
	...People,
	...Products,
	...Roles,
	...Users,
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
