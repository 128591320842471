import { render, staticRenderFns } from "./EjOrderIcon.vue?vue&type=template&id=2e77d804&scoped=true&"
import script from "./EjOrderIcon.vue?vue&type=script&lang=js&"
export * from "./EjOrderIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e77d804",
  null
  
)

export default component.exports