import store from "@/store";

const routes = [
	{
		path: '/roles',
		name: 'roles',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "roles" */ '../views/roles/index'),
		meta: {
			title: 'Grupos de permissão'
		}
	},
	{
		path: '/roles/create',
		name: 'roles.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "roles.edit" */ '../views/roles/edit'),
		meta: {
			title: 'Criar Grupo'
		}
	},
	{
		path: '/roles/:id',
		name: 'roles.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "roles.edit" */ '../views/roles/edit'),
		meta: {
			title: 'Editar Grupo'
		}
	}
];

export default routes;
